import React from 'react';

import { CustomInput } from 'reactstrap';

const FileInput = ({ id, name, onChange, invalid, disabled, accept }) => (
  <CustomInput
    type="file"
    id={id}
    name={name}
    accept={accept}
    invalid={invalid}
    onChange={onChange}
    disabled={disabled}
  />
);

export default FileInput;
