import axios from 'axios';

class Api {
  constructor(token) {
    this.url = `${process.env.REACT_APP_CHK_API_HOST}`;
    this.token = token;
    this.apiKey = null;
    this.task = {};
  }

  createHeader() {
    return {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      Authorization: `Bearer ${this.token}`,
    };
  }

  async countTasks(q) {
    let url = `${this.url}/tasks/count`;
    if (q) {
      url += `?q=${q}`;
    }
    const header = this.createHeader();

    try {
      const response = await axios.get(url, { headers: header });
      return response.data.tasksCount;
    } catch (e) {
      if (e.response) {
        console.error(e.response.data);
      }
    }

    return null;
  }

  // eslint-disable-next-line default-param-last
  async getTasks(limit = 10, skip, q, order) {
    let url = `${this.url}/tasks?limit=${limit}`;
    if (skip) {
      url += `&skip=${skip}`;
    }
    if (q) {
      url += `&q=${q}`;
    }
    if (order === 'desc' || order === 'asc') {
      url += `&order=${order}`;
    }
    const header = this.createHeader();

    let data = null;
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.data &&
        response.data.data.length
      ) {
        data = response.data.data;
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async getTask(taskId) {
    const url = `${this.url}/tasks/${taskId}`;
    const header = this.createHeader();

    let data = {};
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.data !== null
      ) {
        data = response.data.data;
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async getTaskCheckItems(taskId, exportType) {
    const requestData = async (url) => {
      const header = this.createHeader();
      try {
        const response = await axios.get(url, { headers: header });

        if (
          response &&
          response.data &&
          response.data.success === true &&
          response.data.data
        ) {
          return response.data.data;
        }
      } catch (e) {
        console.error(e);
      }
      return null;
    };

    let allCheckItems = [];
    let stop = false;
    let requestUrl = `${this.url}/tasks/${taskId}/check-items?limit=1000&type=${exportType}`;

    while (stop === false) {
      // eslint-disable-next-line no-await-in-loop
      const responseData = await requestData(requestUrl);

      if (
        responseData &&
        responseData.checkItemList &&
        responseData.checkItemList.length > 0
      ) {
        allCheckItems = [...allCheckItems, ...responseData.checkItemList];
      }

      if (
        responseData &&
        responseData.nextUrl &&
        responseData.nextUrl !== null
      ) {
        requestUrl = responseData.nextUrl;
      } else {
        stop = true;
      }
    }

    return allCheckItems;
  }

  async exportTaskCheckItems(taskId, recipients, exportType) {
    const requestUrl = `${this.url}/tasks/${taskId}/check-items`;
    const header = this.createHeader();
    const payload = {
      recipients,
      type: exportType,
    };
    try {
      const response = await axios.post(requestUrl, payload, {
        headers: header,
      });

      if (response && response.data.success === true) {
        return { success: true };
      }
    } catch (e) {
      if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (e.response && e.response.data && e.response.data.error) {
          const { code } = e.response.data.error;
          return { success: false, message: code };
        }
      } else if (e.request) {
        return { success: false, message: 'No response from server' };
      } else {
        return { success: false, message: e.message };
      }
    }
    return null;
  }

  async getCheckItem(taskId, itemCount = 1) {
    const url = `${this.url}/tasks/${taskId}/checkable-item?limit=${itemCount}`;
    const header = this.createHeader();
    let data = [];
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.data.length > 0 &&
        response.data.data[0] !== null
      ) {
        data = response.data.data.map((i) => i.checkItem);
      }
    } catch (e) {
      data = null;
      console.error(e.message);
    }
    // TODO edit backend to return object not object with key checkItem
    return data;
  }

  async joinTask(key) {
    const url = `${this.url}/tasks/join`;
    const header = this.createHeader();

    let response = null;
    let success = false;
    let isExpired = false;
    try {
      response = await axios.post(
        url,
        {},
        {
          headers: { ...header, 'x-api-key': key },
        }
      );
      if (response && response.status === 200) {
        success = response.data.success;
        isExpired = response.data.message === 'Key has been expired';
      }
    } catch (e) {
      console.error(e);
    }

    return { success, isExpired };
  }

  async getRedirectUrl(channel, directUrl) {
    const url = `${this.url}/${channel}/redirect-url?url=${directUrl}`;
    const header = this.createHeader();

    let redirectUrl;
    try {
      const response = await axios.get(url, { headers: header });
      if (response && response.status === 200) {
        redirectUrl = response.data.redirectUrl;
      }
    } catch (error) {
      console.error(error);
    }

    return redirectUrl;
  }

  async getTaskActivityLogs(taskId) {
    const url = `${this.url}/tasks/${taskId}/activity-logs?limit=100`;
    const header = this.createHeader();

    let data = [];
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.data !== null
      ) {
        data = response.data.data;
      }
    } catch (e) {
      data = null;
      console.error(e);
    }

    return data;
  }

  async getCheckedCheckItem(checkItemId, taskId) {
    const url = `${this.url}/tasks/${taskId}/checkable-items/${checkItemId}`;
    const header = this.createHeader();

    let data = [];
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.data.length > 0 &&
        response.data.data[0] !== null
      ) {
        data = response.data.data[0].checkItem;
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async getTaskInvitationKey(taskId) {
    const url = `${this.url}/tasks/${taskId}/invitation-key`;
    const header = this.createHeader();
    const payload = {};

    let data = null;
    let response = null;

    try {
      response = await axios.post(url, payload, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.key
      ) {
        data = response.data.key;
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async saveCheckResult(
    taskId,
    checkItemId,
    taskSchedulerID,
    checkItemResult,
    actionType,
    actionTime
  ) {
    const url = `${this.url}/tasks/${taskId}/checkable-item`;
    const header = this.createHeader();
    const payload = {
      id: checkItemId,
      taskSchedulerID,
      taskCheckitemResult: checkItemResult,
      action: actionType,
      actionTime,
    };
    const data = { status: null };

    let response = null;

    try {
      response = await axios.patch(url, payload, { headers: header });

      if (response && response.data && response.data.success === true) {
        data.status = 'success';
      }
    } catch (e) {
      data.status = 'fail';
      data.message = e.response.data.error
        ? e.response.data.error.message
        : null;
      console.error(e);
    }

    return data;
  }

  async assignUsersToTask(taskId, assignedUsers) {
    const url = `${this.url}/tasks/${taskId}/assign`;
    const header = this.createHeader();
    const payload = {
      userIDs: assignedUsers,
    };
    const data = { status: null };
    let response = null;

    try {
      response = await axios.patch(url, payload, { headers: header });

      if (response && response.data && response.data.success === true) {
        data.status = 'success';
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async unassignUsersToTask(taskId, unassignedUsers) {
    const url = `${this.url}/tasks/${taskId}/unassign`;
    const header = this.createHeader();
    const payload = {
      userIDs: unassignedUsers,
    };
    const data = { status: null };
    let response = null;

    try {
      response = await axios.patch(url, payload, { headers: header });

      if (response && response.data && response.data.success === true) {
        data.status = 'success';
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async createUser({
    username,
    password,
    email,
    name,
    role,
    checkableItemLimit,
  }) {
    const url = `${this.url}/users`;
    const header = this.createHeader();
    const payload = {
      username,
      password,
      email,
      name,
      role,
      checkableItemLimit,
    };

    let result = null;

    try {
      const response = await axios.post(url, payload, { headers: header });
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async getUsersAssignedToTask(taskID) {
    const url = `${this.url}/tasks/${taskID}/assigned-users`;
    const header = this.createHeader();

    let data = [];
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (response && response.data && response.data.assignedUsers) {
        data = response.data.assignedUsers;
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async getUsers() {
    const url = `${this.url}/users`;
    const header = this.createHeader();

    let data = [];
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.data
      ) {
        data = response.data.data;
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async getUser(userId) {
    const url = `${this.url}/users/${userId}`;
    const header = this.createHeader();

    let data = {};
    let response = null;

    try {
      response = await axios.get(url, { headers: header });

      if (
        response &&
        response.data &&
        response.data.success === true &&
        response.data.data
      ) {
        data = response.data.data;
      }
    } catch (e) {
      console.error(e);
    }

    return data;
  }

  async updateUserCheckableItemLimit({ userId, userCheckableItemLimit }) {
    const url = `${this.url}/users/${userId}/checkable-item-limit`;
    const header = this.createHeader();
    const payload = { checkableItemLimit: Number(userCheckableItemLimit) };
    let result;

    try {
      const response = await axios.patch(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async updateUserProfile({ userId, userEmail, userName }) {
    const url = `${this.url}/users/${userId}/profile`;
    const header = this.createHeader();
    const payload = {
      email: userEmail,
      name: userName,
    };

    let result = null;

    try {
      const response = await axios.patch(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async updateUserPassword({ userId, userPassword }) {
    const url = `${this.url}/users/${userId}/password`;
    const header = this.createHeader();
    const payload = {
      password: userPassword,
    };

    let result = null;

    try {
      const response = await axios.patch(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.success
      ) {
        result = response.data.success;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async skipCheckItem(taskId, checkItemId) {
    const url = `${this.url}/tasks/${taskId}/skip-checkable-item/${checkItemId}`;
    const header = this.createHeader();
    let response = null;

    try {
      response = await axios.get(url, { headers: header });
    } catch (error) {
      console.error(error);
    }

    if (response && response.data) {
      return response.data.success;
    }

    return false;
  }

  async getUserStatistic(taskId, userId) {
    const url = `${this.url}/users/${userId}/statistic?taskId=${taskId}`;
    const header = this.createHeader();
    let response = null;
    try {
      response = await axios.get(url, { headers: header });
    } catch (e) {
      console.error(e);
    }
    const data =
      response && response.status === 200
        ? response.data.data
        : { checkedItemCount: null, remainItemCount: null };
    return data;
  }

  async createTask({
    taskName,
    resultType,
    resultConfiguration,
    checkItemType,
    taskStatus,
    paymentRate,
    maximumReservedCheckItem,
    taskScheduler,
  }) {
    const url = `${this.url}/tasks`;
    const header = this.createHeader();
    const payload = {
      name: taskName,
      status: taskStatus,
      taskScheduler,
      configuration: {
        checkItemType,
        paymentRate,
        maximumReservedCheckItemInSeconds: maximumReservedCheckItem,
        resultTypeConfiguration: {
          type: resultType,
          id: '100',
          configuration: resultConfiguration,
        },
      },
    };

    let result = null;

    try {
      const response = await axios.post(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data.length > 0
      ) {
        [result] = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async createTaskScheduler({
    taskId,
    taskStartDateTime,
    taskEndDateTime,
    taskDataFilter,
  }) {
    const url = `${this.url}/tasks/${taskId}/scheduler`;
    const header = this.createHeader();
    const payload = {
      schedule: {
        start: taskStartDateTime,
        end: taskEndDateTime,
      },
      // TODO: change after/before to start/end
      dataFilter: taskDataFilter,
    };

    let result = null;

    try {
      const response = await axios.post(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async deleteTask({ taskId }) {
    const url = `${this.url}/tasks/${taskId}`;
    const header = this.createHeader();

    let result = null;

    try {
      const response = await axios.delete(url, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.success
      ) {
        result = response.data.success;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async updateTaskScheduler({
    taskId,
    taskStartDateTime,
    taskEndDateTime,
    taskDataFilter,
  }) {
    const url = `${this.url}/tasks/${taskId}/scheduler`;
    const header = this.createHeader();
    const payload = {
      schedule: {
        start: taskStartDateTime,
        end: taskEndDateTime,
      },
      dataFilter: taskDataFilter,
    };

    let result = null;

    try {
      const response = await axios.patch(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async updateTaskConfiguration({
    taskId,
    checkItemType,
    paymentRate,
    maximumReservedCheckItem,
    resultType,
    resultConfiguration,
  }) {
    const url = `${this.url}/tasks/${taskId}/configuration`;
    const header = this.createHeader();
    const payload = {
      configuration: {
        checkItemType,
        paymentRate,
        maximumReservedCheckItemInSeconds: maximumReservedCheckItem,
        resultTypeConfiguration: {
          type: resultType,
          id: '100',
          configuration: resultConfiguration,
        },
      },
    };

    let result = null;

    try {
      const response = await axios.patch(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async updateTaskInformation({ taskId, taskName, taskStatus }) {
    const url = `${this.url}/tasks/${taskId}/information`;
    const header = this.createHeader();
    const payload = {
      name: taskName,
      status: taskStatus,
    };

    let result = null;

    try {
      const response = await axios.patch(url, payload, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.data
      ) {
        result = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async generateApiKey(taskId, permissions = {}) {
    const url = `${this.url}/tasks/${taskId}/api-keys`;
    const header = this.createHeader();
    let response = null;
    try {
      response = await axios.post(url, permissions, { headers: header });
    } catch (e) {
      console.error(e);
    }
    if (response && response.status === 200) {
      this.apiKey = response.data.apiKey.key;
      return true;
    }

    return false;
  }

  async saveCheckItem(taskId, taskType, data) {
    const url = `${this.url}/tasks/${taskId}/checkable-item`;
    const header = this.createHeader();
    const payload = {
      type: taskType,
      checkItems: data,
    };

    let response = null;

    try {
      response = await axios.post(url, payload, {
        headers: { ...header, 'x-api-key': this.apiKey },
      });
    } catch (e) {
      console.error(e);
    }

    return response && response.status === 200 ? response.data.ack : false; // { checkedItemCount: null, remainItemCount: null };
  }

  async syncTask(taskId, syncScheduler) {
    const url = `${this.url}/tasks/${taskId}/sync`;
    const header = this.createHeader();

    let result;

    try {
      const response = await axios.put(url, syncScheduler, { headers: header });
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.taskScheduler
      ) {
        result = response.data.taskScheduler;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async deleteUser(userId) {
    const url = `${this.url}/users/${userId}`;
    const header = this.createHeader();

    let result = null;

    try {
      const response = await axios.delete(url, { headers: header });

      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.success
      ) {
        result = response.data.success;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  async getLabelStatistic(taskId) {
    const requestData = async (url) => {
      const header = this.createHeader();
      try {
        const response = await axios.get(url, { headers: header });

        if (
          response &&
          response.data &&
          response.data.success === true &&
          response.data.data
        ) {
          return response.data.data;
        }
      } catch (e) {
        console.error(e);
      }
      return null;
    };

    const requestUrl = `${this.url}/tasks/${taskId}/checked-item-statistic`;

    return requestData(requestUrl);
  }

  async clearReservedCheckItems(taskID, userIDs) {
    const url = `${this.url}/tasks/${taskID}/clear-reserved-check-items`;
    const header = this.createHeader();

    try {
      return (
        await axios.delete(url, {
          headers: header,
          data: { userIDs },
        })
      ).data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async configureUploadSchedule(taskId, upload) {
    const url = `${this.url}/tasks/${taskId}/upload`;
    const header = this.createHeader();

    try {
      const response = await axios.put(url, upload, { headers: header });
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.taskScheduler
      ) {
        return response.data.taskScheduler;
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  }
}

export default Api;
