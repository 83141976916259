import {
  CLEAR_SELECTED_TASK,
  CLEAR_SELECTED_USER_STAT,
  RESET_TASK,
  SET_SELECTED_CONFIG,
  SET_SELECTED_TASK,
  SET_SELECTED_USER_STAT,
  SET_SELECTED_TASK_LIST_PAGE,
  SET_TASK,
  SET_TASKS,
  SET_TASKS_COUNT,
  UPDATE_TASK_VALUE,
  SET_TASKS_QUERY,
  SET_SYNC,
  RESET_SYNC,
  SET_UPLOAD,
  RESET_UPLOAD,
  UPDATE_SYNC_VALUE,
  SET_TASK_EXPORT,
  RESET_TASK_EXPORT,
  UPDATE_TASK_EXPORT_VALUE,
  UPDATE_SELECTED_TASK_VALUE,
} from '../constants/actions';
import Datetime from '../utils/datetime';
import {
  formatSelectedConfig,
  formatSelectedTask,
  formatTask,
  formatTaskList,
} from '../utils/format';

const initialState = {
  task: {
    id: null,
    name: null,
    type: 'HIERARCHY_LABEL', // MULTIPLE_LABEL or HIERARCHY_LABEL
    config: null,
    checkItemType: 'check-it-off', // zocial-eye-v2 or text or redsmith
    checkItemImportType: 'sync', // 'sync' or 'upload'; currently only used for `check-it-off` type
    taskPeriod: {
      start: Datetime.now().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      end: Datetime.nextMonth().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 59,
      }),
    },
    zocialEyeV1DataFilterPostTime: {
      start: Datetime.lastMonth(),
      end: Datetime.now(),
    },
    zocialEyeV1DataFilterSentiment: [],
    zocialEyeV1DataFilterSource: [],
    zocialEyeV1DataFilter: {
      version: 'zocial-eye-v1.0',
      postTime: {
        start: Datetime.lastMonth().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        end: Datetime.now().set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59,
        }),
      },
      sentiment: ['Negative', 'Neutral', 'Positive'],
      source: [
        'facebook',
        'twitter',
        'instagram',
        'forum',
        'news',
        'blog',
        'youtube',
      ],
    },
    zocialEyeV2DataFilterPostTime: {
      start: Datetime.lastMonth(),
      end: Datetime.now(),
    },
    zocialEyeV2DataFilterSentiment: [],
    zocialEyeV2DataFilterSource: [],
    zocialEyeV2DataFilter: {
      version: 'zocial-eye-v2',
      postTime: {
        start: Datetime.lastMonth().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        end: Datetime.now().set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59,
        }),
      },
      sentiment: ['Negative', 'Neutral', 'Positive'],
      source: [
        'facebook',
        'twitter',
        'instagram',
        'youtube',
        'forum',
        'news',
        'other',
        'tiktok',
      ],
    },
    redsmithV1DataFilterPostTime: {
      start: Datetime.lastMonth(),
      end: Datetime.now(),
    },
    redsmithV1DataFilterSentiment: [],
    redsmithV1DataFilterChannel: [],
    redsmithV1DataFilter: {
      version: 'redsmith-v1',
      postTime: {
        start: Datetime.lastMonth().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        end: Datetime.now().set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59,
        }),
      },
      sentiment: ['Negative', 'Neutral', 'Positive'],
      channel: [
        'facebook',
        'twitter',
        'instagram',
        'youtube',
        'tiktok',
        'forum',
        'news',
        'blog',
      ],
    },
    checkItOffSyncDataFilterPostTime: {
      start: Datetime.lastMonth(),
      end: Datetime.now(),
    },
    checkItOffSyncDataFilterSentiment: [],
    checkItOffSyncDataFilterChannel: [],
    checkItOffSyncDataFilter: {
      version: 'check-it-off',
      postTime: {
        start: Datetime.lastMonth().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        end: Datetime.now().set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59,
        }),
      },
      sentimentFromZe: ['negative', 'neutral', 'positive'],
      channel: [
        'facebook',
        'x',
        'instagram',
        'youtube',
        'tiktok',
        'forum',
        'news',
        'other',
      ],
    },
    checkItOffUploadDataFilter: {
      version: 'check-it-off',
      upload: {
        headers: [],
        headerMapping: {},
      },
    },
    paymentType: 'flat', // flat or socialChannel
    flatPaymentRate: {
      type: 'flat',
      compensationPerItem: 0.35,
      currency: 'thb',
    },
    socialChannelPaymentRate: {
      type: 'socialChannel',
      compensationSettings: {
        facebook: 0.35,
        x: 0.35,
        instagram: 0.35,
        youtube: 0.35,
        tiktok: 0.35,
        forum: 0.35,
        news: 0.35,
        blog: 0.35,
      },
      currency: 'thb',
    },
    status: 'enabled', // enabled or disabled
  },
  tasks: [],
  tasksCount: 0,
  tasksQuery: '',
  selectedTaskListPage: 1,
  selectedTask: {},
  selectedConfig: {},
  selectedCheckResult: {
    id: null,
    taskCheckitemResult: {
      type: 'multiple',
      checkResultList: [
        {
          resultList: [
            {
              group: 'Sentiment',
              label: 'Positive',
              value: 'positive',
            },
          ],
        },
      ],
    },
    taskNote: '',
  },
  selectedUserStat: {
    userId: null,
    checkedItemCount: 0,
    remainItemCount: 0,
    skippingCount: 0,
  },
  sync: { cronInSecond: 43200 },
  upload: {
    headers: [],
    headerMapping: {},
  },
  taskExportCheckItems: {
    recipients: [],
    type: 'checked',
  },
};

const setSelectedTaskListPage = (state, action) => {
  const { selectedTaskListPage } = action.payload;
  return { ...state, selectedTaskListPage };
};

const setTasksCount = (state, action) => {
  const { tasksCount } = action.payload;
  return { ...state, tasksCount };
};

const setTasksQuery = (state, action) => {
  const { tasksQuery } = action.payload;
  return { ...state, tasksQuery };
};

const setTasks = (state, action) => {
  const { tasks: rawTasks } = action.payload;
  const formattedTasks = rawTasks
    ? rawTasks.map((task) => formatTaskList(task))
    : null;
  return { ...state, tasks: formattedTasks };
};

const setSelectedTask = (state, action) => {
  const selectedTask = formatSelectedTask(action.payload.selectedTask);
  return { ...state, selectedTask };
};

const setSelectedConfig = (state, action) => {
  const selectedConfig = formatSelectedConfig(action.payload.selectedConfig);
  return { ...state, selectedConfig };
};

const setSelectedUserStat = (state, action) => {
  const { selectedUserStat } = action.payload;
  return { ...state, selectedUserStat };
};

const setTask = (state, action) => {
  const task = formatTask(action.payload.task);
  return { ...state, task };
};

const setSync = (state, action) => {
  const { sync } = action.payload;
  return { ...state, sync };
};

const setUpload = (state, action) => {
  const { upload } = action.payload;
  return { ...state, upload };
};

const resetTask = (state) => {
  const { task } = initialState;
  return { ...state, task };
};

const resetSync = (state) => {
  const { sync } = initialState;
  return { ...state, sync };
};

const resetUpload = (state) => {
  const { upload } = initialState;
  return { ...state, upload };
};

const setTaskExportCheckItems = (state, action) => {
  const { taskExportCheckItems } = action.payload;
  return { ...state, taskExportCheckItems };
};

const resetTaskExportCheckItems = (state) => {
  const { taskExportCheckItems } = initialState;
  return { ...state, taskExportCheckItems };
};

const clearSelectedTask = (state) => ({ ...state, selectedTask: {} });

const clearSelectedUserStat = (state) => ({ ...state, selectedUserStat: {} });

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TASKS_COUNT:
      return setTasksCount(state, action);
    case SET_TASKS_QUERY:
      return setTasksQuery(state, action);
    case SET_SELECTED_TASK_LIST_PAGE:
      return setSelectedTaskListPage(state, action);
    case SET_TASKS:
      return setTasks(state, action);
    case SET_TASK:
      return setTask(state, action);
    case RESET_TASK:
      return resetTask(state);
    case SET_SELECTED_TASK:
      return setSelectedTask(state, action);
    case UPDATE_SELECTED_TASK_VALUE:
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          [action.payload.field]: action.payload.value,
        },
      };
    case CLEAR_SELECTED_TASK:
      return clearSelectedTask(state);
    case CLEAR_SELECTED_USER_STAT:
      return clearSelectedUserStat(state);
    case SET_SELECTED_CONFIG:
      return setSelectedConfig(state, action);
    case SET_SELECTED_USER_STAT:
      return setSelectedUserStat(state, action);
    case UPDATE_TASK_VALUE:
      return {
        ...state,
        task: {
          ...state.task,
          [action.payload.field]: action.payload.value,
        },
      };
    case SET_SYNC:
      return setSync(state, action);
    case RESET_SYNC:
      return resetSync(state);
    case SET_UPLOAD:
      return setUpload(state, action);
    case RESET_UPLOAD:
      return resetUpload(state);
    case UPDATE_SYNC_VALUE:
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.payload.field]: action.payload.value,
        },
      };
    case SET_TASK_EXPORT:
      return setTaskExportCheckItems(state, action);
    case RESET_TASK_EXPORT:
      return resetTaskExportCheckItems(state);
    case UPDATE_TASK_EXPORT_VALUE:
      return {
        ...state,
        taskExportCheckItems: {
          ...state.taskExportCheckItems,
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default { state: initialState, reducer };
