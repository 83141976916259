import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarAlt,
  faLocationCircle,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faLocationCircle, faUser, faCalendarAlt);

const mapping = {
  className: {
    facebook: 'facebook',
    twitter: 'x',
    x: 'x',
    instagram: 'instagram',
    youtube: 'youtube',
    blog: 'blog',
    news: 'news',
    forum: 'forum',
    tiktok: 'tiktok',
  },
  title: {
    facebook: 'Facebook',
    twitter: 'X',
    x: 'X',
    instagram: 'Instagram',
    youtube: 'YouTube',
    blog: 'Blog',
    news: 'News',
    forum: 'Forum',
    tiktok: 'TikTok',
  },
};

const validChannels = [
  'facebook',
  'twitter',
  'instagram',
  'youtube',
  'blog',
  'news',
  'forum',
  'tiktok',
];

const isValidChannel = (channel) => {
  if (!channel) return false;
  return validChannels.includes(channel.toLowerCase());
};

const isValidPostTime = (postTime) => {
  if (!postTime) return false;
  if (postTime === 'Invalid date') return false;
  return true;
};

const getAttribute = (channel, attribute) => {
  if (!mapping[attribute][channel]) {
    return 'unknown';
  }
  return mapping[attribute][channel];
};

const InformationView = ({ place, postTime, name, channel }) => {
  const className = channel ? getAttribute(channel, 'className') : '';
  const title = channel ? getAttribute(channel, 'title') : '';
  return (
    <div className="information-view">
      {isValidChannel(channel) && <div className={className}> {title}</div>}
      {place && (
        <div>
          <FontAwesomeIcon icon={['fas', 'location-circle']} /> {place}
        </div>
      )}
      {name && (
        <div>
          <FontAwesomeIcon icon={['fas', 'user']} /> {name}
        </div>
      )}
      {isValidPostTime(postTime) && (
        <div>
          <FontAwesomeIcon icon={['fas', 'calendar-alt']} /> {postTime}
        </div>
      )}
    </div>
  );
};

export default InformationView;
