import React, { useState, useEffect } from 'react';

import htmlParser from 'react-html-parser';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

const TextImportOptions = ({
  columns,
  mandatoryKeys,
  optionalKeys,
  setDisabledSaveButton,
  updateHeaderMapping,
}) => {
  const dataKeys = [...mandatoryKeys, ...optionalKeys];
  const dataSubtexts = {
    channel: 'Select a column if needed to <b>sync Spider</b>.',
    title: "Select a column if needed to <b>show the message's title</b>.",
    mainKeyword: 'Select a column if needed to <b>highlight the keyword</b>.',
    subKeyword: 'Select a column if needed to <b>highlight the keyword</b>.',
    directUrl:
      'Select a column if needed to <b>show link and embedded url</b>.',
  };

  const initialTextColumns = Object.fromEntries(
    dataKeys.map((key) => [key, ''])
  );
  const [textColumns, setTextColumns] = useState(initialTextColumns);

  const capitalizeFromCamelCase = (text) =>
    text[0].toUpperCase() +
    text
      .split(/(?=[A-Z])/)
      .join(' ')
      .slice(1);

  useEffect(() => {
    updateHeaderMapping(
      Object.fromEntries(
        Object.entries(textColumns).filter(
          ([key, value]) => !!value && value !== '__undefined__'
        )
      )
    );
    setDisabledSaveButton(!mandatoryKeys.every((key) => textColumns[key]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textColumns]);

  return (
    <div className="text-import-options">
      {dataKeys.map((key) => (
        <FormGroup key={key}>
          <Label for="colId">{capitalizeFromCamelCase(key)}</Label>
          <Input
            type="select"
            id={`col-${key}`}
            value={textColumns[key]}
            onChange={(e) =>
              setTextColumns({ ...textColumns, [key]: e.target.value })
            }
          >
            <option value="">-- Please select a column --</option>
            {optionalKeys.includes(key) && (
              <option value="__undefined__" key={`col-${key}-undefined`}>
                Not defined
              </option>
            )}
            {columns.map((column) => (
              <option value={column} key={`col-${key}-${column}`}>
                {column}
              </option>
            ))}
          </Input>
          {key in dataSubtexts && (
            <FormText color="muted">{htmlParser(dataSubtexts[key])}</FormText>
          )}
        </FormGroup>
      ))}
    </div>
  );
};

export default TextImportOptions;
