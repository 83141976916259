import React, { useContext, useEffect, useState } from 'react';

import { useAlert } from 'react-alert';
import { Redirect, useHistory } from 'react-router-dom';

import Body from '../components/templates/Body';
import Footer from '../components/templates/Footer';
import Header from '../components/templates/Header';
import Layout from '../components/templates/Layout';
import TaskAddCheckItemContainer from '../components/templates/TaskAddCheckItemContainer';
import {
  RESET_SYNC,
  RESET_UPLOAD,
  SET_SELECTED_TASK,
  SET_SYNC,
  SET_UPLOAD,
  UPDATE_SELECTED_TASK_VALUE,
} from '../constants/actions';
import {
  ALERT_CHECKITEM_IMPORT_ERROR,
  ALERT_CHECKITEM_IMPORT_MANDATORY_KEY_ERROR,
  ALERT_CHECKITEM_IMPORT_SUCCESS,
  ALERT_CHECKITEM_IMPORT_WARNING,
  ALERT_TASK_CONFIGURE_UPLOAD_SCHEDULE_ERROR,
  ALERT_TASK_INACTIVE_ERROR,
  ALERT_TASK_NOT_FOUND_ERROR,
  ALERT_TASK_SYNC_FAILED,
  ALERT_TASK_SYNC_SUCCESS,
} from '../constants/messages';
import { TASK_LIST } from '../constants/route';
import {
  FETCHING,
  INITIAL,
  PENDING,
  REDIRECT,
  RENDERED,
} from '../constants/state';
import Api from '../services/api';
import { AppContext } from '../store';
import { isTaskScheduleActive, to } from '../utils/utils';

const TaskAddCheckItem = ({ match }) => {
  const { state, dispatch } = useContext(AppContext);
  const [stateStatus, setStateStatus] = useState(INITIAL);
  const [data, setData] = useState(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [modeChangeDisabled, setModeChangeDisabled] = useState(false);
  const [task, setTask] = useState(null);
  const [isSyncMode, setIsSyncMode] = useState(false);

  const uploadMandatoryKeys = ['_id', 'message'];
  const uploadOptionalKeys = [
    'channel',
    'title',
    'mainKeyword',
    'subKeyword',
    'directUrl',
  ];

  const alert = useAlert();
  const history = useHistory();
  const {
    auth,
    task: { selectedTask, sync, upload },
  } = state;

  const { id: taskId } = match.params;

  const backToTaskList = () => {
    history.push(to(TASK_LIST));
  };

  const syncTask = async () => {
    setDisabledSaveButton(true);
    setModeChangeDisabled(true);
    const api = new Api(auth.user.token);

    const response = await api.syncTask(taskId, sync);
    if (response) {
      alert.show(ALERT_TASK_SYNC_SUCCESS, {
        timeout: 3000,
        type: 'success',
      });
      backToTaskList();
    } else {
      alert.show(ALERT_TASK_SYNC_FAILED, {
        timeout: 3000,
        type: 'error',
      });
    }
    setDisabledSaveButton(false);
    setModeChangeDisabled(false);
  };

  const mapDataWithHeaderMapping = (data) => {
    const headerMapping = upload?.headerMapping;
    if (!headerMapping) return null;

    if (!uploadMandatoryKeys.every((key) => headerMapping[key])) return null;

    const uploadKeyTypeMapping = {
      mainKeyword: (value) => value.split(','),
      subKeyword: (value) => value.split(','),
    };

    const mappedData = data.map((d) => ({
      data: Object.fromEntries(
        Object.entries(headerMapping).map(([key, value]) => [
          key,
          d.metaData[value] && uploadKeyTypeMapping[key]
            ? uploadKeyTypeMapping[key](d.metaData[value])
            : d.metaData[value] || null,
        ])
      ),
      metaData: d.metaData,
    }));
    return mappedData;
  };

  const configureUploadSchedule = async (upload) => {
    const api = new Api(auth.user.token);
    return api.configureUploadSchedule(taskId, upload);
  };

  const saveCheckItemInChunk = async (data, chunkSize) => {
    const api = new Api(auth.user.token);
    await api.generateApiKey(taskId);
    const PromisedResponses = [];

    for (let i = 0; i < data.length; i += chunkSize) {
      const slicedData = data.slice(i, i + chunkSize);
      PromisedResponses.push(
        api.saveCheckItem(taskId, selectedTask.config.checkItemType, slicedData)
      );
    }
    const responses = [].concat(
      ...(await Promise.all(PromisedResponses).then((response) => response))
    );
    const successCount = responses.reduce((acc, val) => acc + +val, 0);

    return successCount;
  };

  const saveCheckItem = async () => {
    setDisabledSaveButton(true);
    setModeChangeDisabled(true);
    if (
      !isSyncMode &&
      selectedTask.schedule?.dataFilter?.data?.upload?.headers?.length === 0
    ) {
      const schedule = await configureUploadSchedule(upload);
      if (schedule) {
        dispatch({
          type: UPDATE_SELECTED_TASK_VALUE,
          payload: { field: 'schedule', value: schedule },
        });
      } else {
        alert.show(ALERT_TASK_CONFIGURE_UPLOAD_SCHEDULE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
        setDisabledSaveButton(false);
        setModeChangeDisabled(false);
        return;
      }
    }

    const mappedData = mapDataWithHeaderMapping(data);
    if (!mappedData) {
      alert.show(ALERT_CHECKITEM_IMPORT_MANDATORY_KEY_ERROR, {
        timeout: 3000,
        type: 'error',
      });
      setDisabledSaveButton(false);
      setModeChangeDisabled(false);
      return;
    }
    const successCount = await saveCheckItemInChunk(mappedData, 200);
    if (mappedData.length === successCount) {
      alert.show(ALERT_CHECKITEM_IMPORT_SUCCESS, {
        timeout: 3000,
        type: 'success',
      });
    } else if (successCount > 0) {
      alert.show(
        ALERT_CHECKITEM_IMPORT_WARNING.replace(
          ':success',
          successCount
        ).replace(':total', data.length),
        {
          timeout: 3000,
          type: 'warning',
        }
      );
    } else {
      alert.show(ALERT_CHECKITEM_IMPORT_ERROR, {
        timeout: 3000,
        type: 'error',
      });
    }
    setDisabledSaveButton(false);
    setModeChangeDisabled(false);
  };

  useEffect(() => {
    const getTask = async (token, taskId) => {
      const api = new Api(token);
      const task = await api.getTask(taskId);

      if (
        task &&
        task.currentSchedule &&
        !isTaskScheduleActive(task.currentSchedule.schedule)
      ) {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_INACTIVE_ERROR, {
          timeout: 3000,
          type: 'error',
        });
        return;
      }

      if (
        task.currentSchedule.dataFilter?.data?.sync ||
        task.currentSchedule.dataFilter?.zocialEyeData?.sync ||
        task.currentSchedule.dataFilter?.redsmithData?.sync
      ) {
        setIsSyncMode(true);
        setModeChangeDisabled(true);
        dispatch({
          type: SET_SYNC,
          payload: {
            sync:
              task.currentSchedule.dataFilter?.data?.sync ||
              task.currentSchedule.dataFilter?.zocialEyeData?.sync ||
              task.currentSchedule.dataFilter?.redsmithData?.sync,
          },
        });
        dispatch({ type: RESET_UPLOAD });
      } else {
        setIsSyncMode(false);
        setModeChangeDisabled(false);
        dispatch({
          type: SET_UPLOAD,
          payload: { upload: task.currentSchedule.dataFilter?.data?.upload },
        });
        dispatch({ type: RESET_SYNC });
      }
      setTask(task);
      if (
        task.configuration.checkItemType === 'redsmith-v1' ||
        (task.configuration.checkItemType === 'check-it-off' &&
          !task.currentSchedule.dataFilter?.data?.upload)
      ) {
        setIsSyncMode(true);
        setModeChangeDisabled(true);
      }

      if (task && task.id) {
        dispatch({
          type: SET_SELECTED_TASK,
          payload: { selectedTask: task },
        });
      } else {
        setStateStatus(REDIRECT);
        alert.show(ALERT_TASK_NOT_FOUND_ERROR, {
          timeout: 3000,
          type: 'error',
        });
      }
    };

    switch (stateStatus) {
      case INITIAL:
        setStateStatus(PENDING);
        break;
      case PENDING:
        getTask(auth.user.token, taskId);
        setStateStatus(FETCHING);
        break;
      case FETCHING:
        if (task) {
          setStateStatus(RENDERED);
        }
        break;
      case RENDERED:
        break;
      default:
        break;
    }
  }, [stateStatus, task]);

  return (
    <>
      {stateStatus === REDIRECT && <Redirect to={TASK_LIST} />}
      {stateStatus === RENDERED && (
        <div id="task_add_checkitem">
          <Layout>
            <Header />
            <Body>
              <TaskAddCheckItemContainer
                saveCheckItem={saveCheckItem}
                syncTask={syncTask}
                disabledSaveButton={disabledSaveButton}
                setDisabledSaveButton={setDisabledSaveButton}
                modeChangeDisabled={modeChangeDisabled}
                setModeChangeDisabled={setModeChangeDisabled}
                isSyncMode={isSyncMode}
                setIsSyncMode={setIsSyncMode}
                data={data}
                setData={setData}
                uploadMandatoryKeys={uploadMandatoryKeys}
                uploadOptionalKeys={uploadOptionalKeys}
              />
            </Body>
            <Footer />
          </Layout>
        </div>
      )}
    </>
  );
};

export default TaskAddCheckItem;
