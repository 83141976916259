import React, { useMemo } from 'react';

import { FormGroup, Input, Label } from 'reactstrap';

import CheckItOffSyncDataFilter from '../molecules/CheckItOffSyncDataFilter';
import RedsmithV1DataFilter from '../molecules/RedsmithV1DataFilter';
import ZocialEyeV1DataFilter from '../molecules/ZocialEyeV1DataFilter';
import ZocialEyeV2DataFilter from '../molecules/ZocialEyeV2DataFilter';

const TaskDataFilterForm = ({
  mode,
  checkItemType,
  checkItemImportType,
  zocialEyeV1DataFilter,
  setZocialEyeV1DataFilter,
  setCheckItemType,
  setCheckItemImportType,
  setCheckItemTypeDisabled,
  redsmithV1DataFilter,
  setRedsmithV1DataFilter,
  zocialEyeV2DataFilter,
  setZocialEyeV2DataFilter,
  checkItOffSyncDataFilter,
  setCheckItOffSyncDataFilter,
}) => {
  const checkItemTypes = {
    'check-it-off-sync': 'Redsmith 2024',
    'check-it-off-upload': 'Text',
    'redsmith-v1': 'Redsmith v.1',
  };

  if (mode === 'edit') {
    checkItemTypes['zocial-eye-v1.0'] = 'Zocial Eye v1.0';
    checkItemTypes['zocial-eye-v2'] = 'Zocial Eye v2';
    checkItemTypes.text = 'Text (old)';
  }

  const checkItemTypeWithMode = useMemo(() => {
    if (checkItemType === 'check-it-off') {
      return `${checkItemType}-${checkItemImportType}`;
    }

    return checkItemType;
  }, [checkItemType, checkItemImportType]);

  const updateCheckItemType = (value) => {
    if (value === 'check-it-off-sync') {
      setCheckItemType('check-it-off');
      setCheckItemImportType('sync');
    } else if (value === 'check-it-off-upload') {
      setCheckItemType('check-it-off');
      setCheckItemImportType('upload');
    } else {
      setCheckItemType(value);
    }
  };

  return (
    <>
      <FormGroup>
        <Label for="task-checkitem-type" className="select">
          Check item type
          <Input
            type="select"
            name="checkItemType"
            id="task-checkitem-type"
            defaultValue={checkItemTypeWithMode}
            onChange={(e) => updateCheckItemType(e.target.value)}
            disabled={setCheckItemTypeDisabled}
          >
            {setCheckItemTypeDisabled && (
              <option value={checkItemTypeWithMode}>
                {checkItemTypes[checkItemTypeWithMode]}
              </option>
            )}
            {!setCheckItemTypeDisabled &&
              Object.keys(checkItemTypes).map((key) => (
                <option key={key} value={key}>
                  {checkItemTypes[key]}
                </option>
              ))}
          </Input>
        </Label>
      </FormGroup>
      {checkItemType === 'zocial-eye-v1.0' && (
        <ZocialEyeV1DataFilter
          dataFilter={zocialEyeV1DataFilter}
          setDataFilter={setZocialEyeV1DataFilter}
        />
      )}
      {checkItemType === 'zocial-eye-v2' && (
        <ZocialEyeV2DataFilter
          dataFilter={zocialEyeV2DataFilter}
          setDataFilter={setZocialEyeV2DataFilter}
        />
      )}
      {checkItemType === 'redsmith-v1' && (
        <RedsmithV1DataFilter
          dataFilter={redsmithV1DataFilter}
          setDataFilter={setRedsmithV1DataFilter}
        />
      )}
      {checkItemType === 'check-it-off' && checkItemImportType === 'sync' && (
        <CheckItOffSyncDataFilter
          dataFilter={checkItOffSyncDataFilter}
          setDataFilter={setCheckItOffSyncDataFilter}
        />
      )}
    </>
  );
};

export default TaskDataFilterForm;
