import React, { useContext, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Form, FormGroup, FormText, Label } from 'reactstrap';

import { SET_UPLOAD } from '../../constants/actions';
import { AppContext } from '../../store';
import { readCsvFile, readExcelFile } from '../../utils/file';
import { getExcelCellValue } from '../../utils/utils';
import FileInput from '../atoms/FileInput';
import TextImportOptions from '../molecules/TextImportOptions';

const TaskAddCheckItemByUploadFileController = ({
  setDisabledSaveButton,
  setModeChangeDisabled,
  setData,
  mandatoryKeys,
  optionalKeys,
}) => {
  const { state, dispatch } = useContext(AppContext);

  const {
    selectedTask: { config, schedule },
    upload,
  } = state.task;

  const [columns, setColumns] = useState([]);
  const [existingColumns, setExistingColumns] = useState([]);
  const [isValidFile, setIsValidFile] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isShowingColumns, setIsShowingColumns] = useState(false);
  const [isColumnsMatch, setIsColumnsMatch] = useState(true);

  const canSelectColumnMapping = useMemo(
    () =>
      existingColumns.length === 0 &&
      (config?.checkItemType === 'text' ||
        (config?.checkItemType === 'check-it-off' &&
          config?.checkItemImportType === 'upload')),
    [config?.checkItemType, config?.checkItemImportType, existingColumns]
  );

  const updateColumnsFromData = (data) => {
    const columns = Object.keys(data[0].metaData);
    const isColumnsMatch =
      existingColumns.length === 0 ||
      (columns.length === existingColumns.length &&
        columns.every((column, index) => existingColumns[index] === column));
    setColumns(columns);
    setIsColumnsMatch(isColumnsMatch);

    if (!canSelectColumnMapping && isColumnsMatch) {
      setDisabledSaveButton(false);
      setModeChangeDisabled(false);
    }

    dispatch({
      type: SET_UPLOAD,
      payload: {
        upload: {
          ...upload,
          headers: columns,
        },
      },
    });
  };

  const loadCsvFile = async (file) => {
    setIsLoadingFile(true);
    const reader = new FileReader();
    reader.addEventListener('load', async (event) => {
      const data = await readCsvFile(event.target.result);
      const formattedData = data.data.map(({ '': _, ...d }) => ({
        data: {},
        metaData: d,
      }));
      updateColumnsFromData(formattedData);
      setData(formattedData);
      setIsValidFile(true);
      setIsLoadingFile(false);
    });
    reader.readAsText(file);
  };

  const loadExcelFile = async (file) => {
    setIsLoadingFile(true);
    const reader = new FileReader();
    reader.addEventListener('load', async (event) => {
      const result = await readExcelFile(event.target.result);
      const datas = [];
      const worksheet = result.worksheets[0];
      const columns = worksheet.getRow(1);
      worksheet.spliceRows(0, 1);
      worksheet.eachRow((row) => {
        const checkItem = {
          data: {},
          metaData: {},
        };
        columns.values.forEach((col, i) => {
          if (i > 0) {
            const cell = row.getCell(i);
            const cellValue = getExcelCellValue(cell);
            checkItem.metaData[col] = cellValue;
          }
        });
        datas.push(checkItem);
      });

      updateColumnsFromData(datas);
      setData(datas);
      setIsValidFile(true);
      setIsLoadingFile(false);
    });
    reader.readAsArrayBuffer(file);
  };

  const readFile = async (file) => {
    const excelType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const csvType = 'text/csv';
    setDisabledSaveButton(true);
    setModeChangeDisabled(true);

    if (!file) {
      setColumns([]);
      setIsColumnsMatch(true);
      setIsValidFile(true);
      setData(null);
      setDisabledSaveButton(true);
      setModeChangeDisabled(true);
      setIsLoadingFile(false);
      return;
    }

    if (file.type && file.type !== excelType && file.type !== csvType) {
      setIsValidFile(false);
      setData(null);
      setDisabledSaveButton(true);
      setModeChangeDisabled(true);
      setIsLoadingFile(false);
      return;
    }

    if (file.type === excelType) {
      await loadExcelFile(file);
    }

    if (file.type === csvType) {
      await loadCsvFile(file);
    }
  };

  const updateHeaderMapping = (headerMapping) => {
    dispatch({
      type: SET_UPLOAD,
      payload: {
        upload: {
          ...upload,
          headerMapping,
        },
      },
    });
  };

  useEffect(() => {
    const headers = schedule.dataFilter?.data?.upload?.headers || [];
    setIsColumnsMatch(true);
    setExistingColumns(headers);
  }, [schedule.dataFilter?.data?.upload?.headers]);

  return (
    <div className="task-add-checkitem">
      <div className="task-add-checkitem-form">
        <Form className="form">
          <FormGroup>
            <Label for="task-config">Upload</Label>
            <FileInput
              id="exampleCustomFileBrowser"
              accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={async (e) => {
                await readFile(e.target.files[0]);
              }}
            />
            <FormText color={isValidFile ? 'muted' : 'danger'}>
              {isValidFile
                ? 'Only excel and csv files are supported.'
                : 'File is not an excel or csv.'}
            </FormText>
          </FormGroup>
          {isLoadingFile && (
            <div
              className="fa-3x"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <FontAwesomeIcon icon={['far', 'spinner']} pulse />
            </div>
          )}
          {existingColumns.length > 0 && (
            <FormText color={isColumnsMatch ? 'black' : 'danger'}>
              {isColumnsMatch
                ? 'The Excel file should have the same columns as the previous file, in order. '
                : 'Error: The Excel file must contain the same columns as the previous file, in the correct order. '}
              <button
                type="button"
                className="link-button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsShowingColumns(!isShowingColumns);
                }}
              >
                {isShowingColumns ? 'Hide' : 'Show'} columns
              </button>
              <Collapse isOpen={isShowingColumns}>
                <ul>
                  {existingColumns.map((column) => (
                    <li key={column}>{column}</li>
                  ))}
                </ul>
              </Collapse>
            </FormText>
          )}
          {!isLoadingFile && canSelectColumnMapping && columns.length > 0 ? (
            <TextImportOptions
              columns={columns}
              setDisabledSaveButton={setDisabledSaveButton}
              updateHeaderMapping={updateHeaderMapping}
              mandatoryKeys={mandatoryKeys}
              optionalKeys={optionalKeys}
            />
          ) : null}
        </Form>
      </div>
    </div>
  );
};

export default TaskAddCheckItemByUploadFileController;
