/** Pattern: ALERT_{Who}_{Action}_{Status} */

/** Task */
export const ALERT_TASK_CREATE_SUCCESS = 'The task successfully created.';
export const ALERT_TASK_CREATE_ERROR = 'Failed to create task.';
export const ALERT_TASK_UPDATE_SUCCESS = 'The task successfully updated.';
export const ALERT_TASK_UPDATE_ERROR = 'Failed to update task.';
export const ALERT_TASK_NOT_FOUND_ERROR = 'The specified task not found.';
export const ALERT_TASK_INACTIVE_ERROR = 'The task is currently inactive.';
export const ALERT_TASK_NOT_AVAILABLE = 'The task is not available.';
export const ALERT_TASK_NAME_EMPTY_ERROR = 'Task name cannot be empty.';
export const ALERT_TASK_CLEAR_RESERVED_CHECKITEMS_SUCCESS =
  'The reserved checkitems is successfully cleared (:total).';
export const ALERT_TASK_CLEAR_RESERVED_CHECKITEMS_ERROR =
  'Failed to clear reserved checkitems.';
export const ALERT_TASK_CREATE_INVITATION_LINK_SUCCESS =
  'The invitation link has been created.';
export const ALERT_TASK_CREATE_INVITATION_LINK_FAILED =
  'The invitation link failed to create.';
export const ALERT_TASK_SYNC_SUCCESS =
  'The sync configuration successfully set to task.';
export const ALERT_TASK_SYNC_FAILED =
  'Failed to set the sync scheduler to task.';
export const ALERT_TASK_CONFIGURE_UPLOAD_SCHEDULE_ERROR =
  'Failed to configure upload schedule.';

/** User */
export const ALERT_USER_CREATE_SUCCESS = 'The user successfully created.';
export const ALERT_USER_CREATE_ERROR = 'Failed to create user.';
export const ALERT_USER_PROFILE_UPDATE_SUCCESS =
  "The user's profile successfully updated.";
export const ALERT_USER_PASSWORD_UPDATE_SUCCESS =
  "The user's profile and password successfully updated.";
export const ALERT_USER_UPDATE_ERROR = 'Failed to update user.';
export const ALERT_USER_PASSWORD_UPDATE_WARNING =
  "The user's profile updated but failed update password.";
export const ALERT_USER_NOT_FOUND_ERROR = 'The specified user not found.';

/** Check Item */
export const ALERT_CHECKITEM_IMPORT_SUCCESS =
  'Checkitems successfully imported.';
export const ALERT_CHECKITEM_IMPORT_ERROR = 'Failed to import checkitems.';
export const ALERT_CHECKITEM_IMPORT_WARNING =
  'Checkitems imported :success from :total.';
export const ALERT_CHECKITEM_IMPORT_MANDATORY_KEY_ERROR =
  'Failed to import checkitems, mandatory keys are missing.';

export const ALERT_CHECKITEM_SAVE_SUCCESS = 'Results successfully saved.';
export const ALERT_CHECKITEM_REJECT_SUCCESS = 'Results successfully rejected.';
export const ALERT_CHECKITEM_SAVE_ERROR = 'Failed to save results.';
export const ALERT_CHECKITEM_UPDATE_SUCCESS = 'Results successfully updated.';
export const ALERT_CHECKITEM_UPDATE_ERROR = 'Failed to update results.';
export const ALERT_CHECKITEM_SKIP_ERROR = 'Failed to skip checkitem.';
export const ALERT_CHECKITEM_SKIP_SUCCESS = 'Checkitem successfully skipped.';

/** Assign User */
export const ALERT_USER_ASSIGNED_SUCCESS = 'Users successfully assigned.';
export const ALERT_USER_ASSIGNED_ERROR = 'Failed to assign users.';
export const ALERT_USER_DELETE_SUCCESS = 'User successfully deleted.';
export const ALERT_USER_DELETE_ERROR = 'Failed to delete user.';

/** Export Check Items */
export const ALERT_CHECKITEM_EXPORT_SUCCESS =
  'Checkitems successfully exported.';
export const ALERT_CHECKITEM_EXPORT_ERROR = 'Failed to export checkitems.';
export const ALERT_CHECKITEM_EXPORT_NOT_FOUND_ERROR =
  "Failed to export, this task don't have any checkitems";
export const ALERT_CHECKITEM_EXPORT_CHECKED_NOT_FOUND_ERROR =
  "Failed to export, this task don't have any checked checkitems";

/** Export Statistic */
export const ALERT_STATISTIC_EXPORT_SUCCESS =
  'Statistics successfully exported.';
export const ALERT_STATISTIC_EXPORT_ERROR = 'Error to export statistics.';

/** Delete Task */
export const ALERT_TASK_DELETE_SUCCESS = 'Task successfully deleted.';
// export const ALERT_TASK_DELETE_ERROR = 'Task not Found';

/** Join */
export const ALERT_KEY_NOT_FOUND = "Failed to join task 'key' not found.";
export const ALERT_JOIN_TASK_SUCCESS = 'Task successfully joined.';
export const ALERT_JOIN_TASK_EXPIRED = 'Invitation link has been expired.';
export const ALERT_JOIN_TASK_ERROR = 'Failed to join task.';

/** Clear reserved check item in cache */

export const ALERT_CLEAR_CACHE_ERROR =
  "Can't clear reserved check items in cache.";
