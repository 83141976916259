import Datetime from './datetime';
import { canManageTask } from './permission';

const determineCheckItemImportType = (task) => {
  if (task.currentSchedule.dataFilter.data.upload) {
    return 'upload';
  }

  return 'sync';
};

const formatTask = (task) => ({
  id: task.id || null,
  name: task.name || '',
  type: task.configuration.resultTypeConfiguration.type || 'MULTIPLE_LABEL',
  config: task.configuration.resultTypeConfiguration.configuration || null,
  checkItemType: task.configuration.checkItemType || '',
  checkItemImportType:
    task.configuration.checkItemType === 'check-it-off'
      ? determineCheckItemImportType(task)
      : undefined,
  taskPeriod: {
    start: Datetime.toMoment(task.currentSchedule.schedule.start),
    end: Datetime.toMoment(task.currentSchedule.schedule.end),
  },
  zocialEyeV1DataFilterPostTime:
    task.configuration.checkItemType === 'zocial-eye-v1.0'
      ? {
          start: Datetime.toMoment(
            task.currentSchedule.dataFilter.zocialEyeData.postTime.start
          ),
          end: Datetime.toMoment(
            task.currentSchedule.dataFilter.zocialEyeData.postTime.end
          ),
        }
      : null,
  zocialEyeV1DataFilterSentiment:
    task.configuration.checkItemType === 'zocial-eye-v1.0'
      ? task.currentSchedule.dataFilter.zocialEyeData.sentiment
      : null,
  zocialEyeV1DataFilterSource:
    task.configuration.checkItemType === 'zocial-eye-v1.0'
      ? task.currentSchedule.dataFilter.zocialEyeData.source
      : null,
  zocialEyeV1DataFilter:
    task.configuration.checkItemType === 'zocial-eye-v1.0'
      ? {
          version: task.currentSchedule.dataFilter.zocialEyeData.version,
          postTime: {
            start: Datetime.toMoment(
              task.currentSchedule.dataFilter.zocialEyeData.postTime.start
            ),
            end: Datetime.toMoment(
              task.currentSchedule.dataFilter.zocialEyeData.postTime.end
            ),
          },
          sentiment:
            task.currentSchedule.dataFilter.zocialEyeData.sentiment ||
            undefined,
          source:
            task.currentSchedule.dataFilter.zocialEyeData.source || undefined,
        }
      : null,

  zocialEyeV2DataFilterPostTime:
    task.configuration.checkItemType === 'zocial-eye-v2'
      ? {
          start: Datetime.toMoment(
            task.currentSchedule.dataFilter.zocialEyeData.postTime.start
          ),
          end: Datetime.toMoment(
            task.currentSchedule.dataFilter.zocialEyeData.postTime.end
          ),
        }
      : null,
  zocialEyeV2DataFilterSentiment:
    task.configuration.checkItemType === 'zocial-eye-v2'
      ? task.currentSchedule.dataFilter.zocialEyeData.sentiment
      : null,
  zocialEyeV2DataFilterSource:
    task.configuration.checkItemType === 'zocial-eye-v2'
      ? task.currentSchedule.dataFilter.zocialEyeData.source
      : null,
  zocialEyeV2DataFilter:
    task.configuration.checkItemType === 'zocial-eye-v2'
      ? {
          version: task.currentSchedule.dataFilter.zocialEyeData.version,
          postTime: {
            start: Datetime.toMoment(
              task.currentSchedule.dataFilter.zocialEyeData.postTime.start
            ),
            end: Datetime.toMoment(
              task.currentSchedule.dataFilter.zocialEyeData.postTime.end
            ),
          },
          sentiment:
            task.currentSchedule.dataFilter.zocialEyeData.sentiment ||
            undefined,
          source:
            task.currentSchedule.dataFilter.zocialEyeData.source || undefined,
        }
      : null,

  redsmithV1DataFilterPostTime:
    task.configuration.checkItemType === 'redsmith-v1'
      ? {
          start: Datetime.toMoment(
            task.currentSchedule.dataFilter.redsmithData.postTime.start
          ),
          end: Datetime.toMoment(
            task.currentSchedule.dataFilter.redsmithData.postTime.end
          ),
        }
      : null,
  redsmithV1DataFilterSentiment:
    task.configuration.checkItemType === 'redsmith-v1'
      ? task.currentSchedule.dataFilter.redsmithData.sentiment
      : null,
  redsmithV1DataFilterChannel:
    task.configuration.checkItemType === 'redsmith-v1'
      ? task.currentSchedule.dataFilter.redsmithData.channel
      : null,
  redsmithV1DataFilter:
    task.configuration.checkItemType === 'redsmith-v1'
      ? {
          version: task.currentSchedule.dataFilter.redsmithData.version,
          postTime: {
            start: Datetime.toMoment(
              task.currentSchedule.dataFilter.redsmithData.postTime.start
            ),
            end: Datetime.toMoment(
              task.currentSchedule.dataFilter.redsmithData.postTime.end
            ),
          },
          sentiment:
            task.currentSchedule.dataFilter.redsmithData.sentiment || undefined,
          channel:
            task.currentSchedule.dataFilter.redsmithData.channel || undefined,
        }
      : null,

  checkItOffSyncDataFilterPostTime:
    task.configuration.checkItemType === 'check-it-off' &&
    determineCheckItemImportType(task) === 'sync'
      ? {
          start: Datetime.toMoment(
            task.currentSchedule.dataFilter.data.postTime.start
          ),
          end: Datetime.toMoment(
            task.currentSchedule.dataFilter.data.postTime.end
          ),
        }
      : null,
  checkItOffSyncDataFilterSentiment:
    task.configuration.checkItemType === 'check-it-off' &&
    determineCheckItemImportType(task) === 'sync'
      ? task.currentSchedule.dataFilter.data.sentimentFromZe
      : null,
  checkItOffSyncDataFilterChannel:
    task.configuration.checkItemType === 'check-it-off' &&
    determineCheckItemImportType(task) === 'sync'
      ? task.currentSchedule.dataFilter.data.channel
      : null,
  checkItOffSyncDataFilter:
    task.configuration.checkItemType === 'check-it-off' &&
    determineCheckItemImportType(task) === 'sync'
      ? {
          version: task.currentSchedule.dataFilter.data.version,
          postTime: {
            start: Datetime.toMoment(
              task.currentSchedule.dataFilter.data.postTime.start
            ),
            end: Datetime.toMoment(
              task.currentSchedule.dataFilter.data.postTime.end
            ),
          },
          sentimentFromZe:
            task.currentSchedule.dataFilter.data.sentimentFromZe || undefined,
          channel: task.currentSchedule.dataFilter.data.channel || undefined,
        }
      : null,
  checkItOffUploadDataFilter:
    task.configuration.checkItemType === 'check-it-off' &&
    determineCheckItemImportType(task) === 'upload'
      ? {
          version: task.currentSchedule.dataFilter.data.version,
          upload: task.currentSchedule.dataFilter.data.upload,
        }
      : null,

  paymentType: task.configuration.paymentRate.type || 'flat',
  flatPaymentRate:
    task.configuration.paymentRate.type === 'flat'
      ? task.configuration.paymentRate
      : {
          type: 'flat',
          compensationPerItem: 0.35,
          currency: 'thb',
        },
  socialChannelPaymentRate:
    task.configuration.paymentRate.type === 'socialChannel'
      ? task.configuration.paymentRate
      : {
          type: 'socialChannel',
          compensationSettings: {
            facebook: 0.35,
            twitter: 0.35,
            instagram: 0.35,
            youtube: 0.35,
            tiktok: 0.35,
            forum: 0.35,
            news: 0.35,
            blog: 0.35,
          },
          currency: 'thb',
        },
  status: task.status || 'disabled',
});

const formatTaskList = (task) => ({
  id: task.id,
  name: task.name,
  createdBy: task.createdBy.name,
  progress: task.status,
  checker: {
    count: task.assignedTo.length,
  },
  deadline: Datetime.toFormat(
    task.currentSchedule.schedule.end,
    'YYYY-MM-DD[T]HH:mm:ssz[Z]',
    'MMMM DD, YYYY HH:mm',
    'UTC',
    'Asia/Bangkok'
  ),
  config: {
    ...task.configuration,
    checkItemImportType:
      task.configuration.checkItemType === 'check-it-off'
        ? determineCheckItemImportType(task)
        : undefined,
  },
  schedule: task.currentSchedule,
});

const formatSelectedTask = (task) => ({
  ...formatTaskList(task),
  stats: {
    checked: task.currentScheduleStats.checked,
    total: task.currentScheduleStats.total,
  },
});

const formatSelectedConfig = (config) => ({
  type: config.type,
  config: {
    label: 'Root',
    value: 'root',
    children: config.configuration,
  },
});

const formatHierarchyLabelResultType = (config) => {
  const hierarchyConfig = {
    label: 'Root',
    value: 'root',
  };
  let currentDepthConfig = [hierarchyConfig];
  config.forEach((column) => {
    let nextDepthConfig = [];
    currentDepthConfig.forEach((node) => {
      // eslint-disable-next-line no-param-reassign
      node.children = Array.isArray(column) ? column : column[node.label];
      nextDepthConfig = [...nextDepthConfig, ...node.children];
    });
    currentDepthConfig = nextDepthConfig;
  });
  return hierarchyConfig;
};

const formatEmbeddedUrl = (link) => {
  let embeddedUrl;
  if (link !== null && typeof link === 'object' && 'hyperlink' in link) {
    embeddedUrl = link.hyperlink;
  } else if (typeof link === 'string') {
    embeddedUrl = link;
  } else {
    embeddedUrl = null;
  }
  return embeddedUrl;
};

const formatZocialEyeItemV1 = (checkItem) => ({
  id: checkItem.id,
  type: checkItem.checkItem ? checkItem.checkItem.type : '',
  taskID: checkItem.taskID,
  taskSchedulerID: checkItem.taskSchedulerID,
  detail:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.message
      : '',
  channel:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.source
      : '',
  embeddedUrl:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.directURL
      : null,
  username:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.account
      : null,
  postTime:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? Datetime.toFormat(
          checkItem.checkItem.zocialEyeData.postTime,
          'YYYY-MM-DD[T]HH:mm:ssz[Z]',
          'MMMM DD, YYYY HH:mm',
          'UTC',
          'Asia/Bangkok'
        )
      : null,
  displayName:
    checkItem.checkItem && checkItem.checkItem.spiderData
      ? checkItem.checkItem.spiderData.displayName
      : null,
  imageUrl:
    checkItem.checkItem && checkItem.checkItem.spiderData
      ? checkItem.checkItem.spiderData.imageUrl
      : null,
  place:
    checkItem.checkItem && checkItem.checkItem.spiderData
      ? checkItem.checkItem.spiderData.place
      : null,
  post:
    checkItem.checkItem &&
    checkItem.checkItem.spiderData &&
    checkItem.checkItem.spiderData.post
      ? {
          id: checkItem.checkItem.spiderData.post.id,
          message: checkItem.checkItem.spiderData.post.message,
          username: checkItem.checkItem.spiderData.post.username,
          displayName: checkItem.checkItem.spiderData.post.displayName,
          imageUrl: checkItem.checkItem.spiderData.post.imageUrl,
          postTime: Datetime.toFormat(
            checkItem.checkItem.spiderData.post.postTime,
            'YYYY-MM-DD[T]HH:mm:ssz[Z]',
            'MMMM DD, YYYY HH:mm',
            'UTC',
            'Asia/Bangkok'
          ),
        }
      : null,
  comment:
    checkItem.checkItem &&
    checkItem.checkItem.spiderData &&
    checkItem.checkItem.spiderData.comment
      ? {
          id: checkItem.checkItem.spiderData.comment.id,
          message: checkItem.checkItem.spiderData.comment.message,
          username: checkItem.checkItem.spiderData.comment.username,
          displayName: checkItem.checkItem.spiderData.comment.displayName,
          imageUrl: checkItem.checkItem.spiderData.comment.imageUrl,
          postTime: Datetime.toFormat(
            checkItem.checkItem.spiderData.comment.postTime,
            'YYYY-MM-DD[T]HH:mm:ssz[Z]',
            'MMMM DD, YYYY HH:mm',
            'UTC',
            'Asia/Bangkok'
          ),
        }
      : null,
  mainKeyword:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? `${checkItem.checkItem.zocialEyeData.mainKeyword}`
          .split(/[,+]+/)
          .filter((t) => t !== '-')
      : [],
  subKeyword:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? `${checkItem.checkItem.zocialEyeData.subKeyword}`
          .split(/[,+]+/)
          .filter((t) => t !== '-')
      : [],
});

const formatZocialEyeItemV2 = (checkItem) => ({
  id: checkItem.id,
  type: checkItem.checkItem ? checkItem.checkItem.type : '',
  taskID: checkItem.taskID,
  taskSchedulerID: checkItem.taskSchedulerID,
  detail:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.message
      : '',
  channel:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.source
      : '',
  embeddedUrl:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.directURL
      : null,
  username:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.account
      : null,
  postTime:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? Datetime.toFormat(
          checkItem.checkItem.zocialEyeData.postTime,
          'YYYY-MM-DD[T]HH:mm:ssz[Z]',
          'MMMM DD, YYYY HH:mm',
          'UTC',
          'Asia/Bangkok'
        )
      : null,
  displayName: checkItem.checkItem?.zocialEyeData?.account || null,
  imageUrl:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? checkItem.checkItem.zocialEyeData.imageURL
      : null,
  place:
    checkItem.checkItem && checkItem.checkItem.spiderData
      ? checkItem.checkItem.spiderData.place
      : null,
  post:
    checkItem.checkItem &&
    checkItem.checkItem.spiderData &&
    checkItem.checkItem.spiderData.post
      ? {
          id: checkItem.checkItem.spiderData.post.id,
          message: checkItem.checkItem.spiderData.post.message,
          username: checkItem.checkItem.spiderData.post.username,
          displayName: checkItem.checkItem.spiderData.post.displayName,
          imageUrl: checkItem.checkItem.spiderData.post.imageUrl,
          postTime: Datetime.toFormat(
            checkItem.checkItem.spiderData.post.postTime,
            'YYYY-MM-DD[T]HH:mm:ssz[Z]',
            'MMMM DD, YYYY HH:mm',
            'UTC',
            'Asia/Bangkok'
          ),
        }
      : null,
  comment:
    checkItem.checkItem &&
    checkItem.checkItem.spiderData &&
    checkItem.checkItem.spiderData.comment
      ? {
          id: checkItem.checkItem.spiderData.comment.id,
          message: checkItem.checkItem.spiderData.comment.message,
          username: checkItem.checkItem.spiderData.comment.username,
          displayName: checkItem.checkItem.spiderData.comment.displayName,
          imageUrl: checkItem.checkItem.spiderData.comment.imageUrl,
          postTime: Datetime.toFormat(
            checkItem.checkItem.spiderData.comment.postTime,
            'YYYY-MM-DD[T]HH:mm:ssz[Z]',
            'MMMM DD, YYYY HH:mm',
            'UTC',
            'Asia/Bangkok'
          ),
        }
      : null,
  mainKeyword:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? `${checkItem.checkItem.zocialEyeData.mainKeyword}`
          .split(/[,+]+/)
          .filter((t) => t !== '-')
      : [],
  subKeyword:
    checkItem.checkItem && checkItem.checkItem.zocialEyeData
      ? `${checkItem.checkItem.zocialEyeData.subKeyword}`
          .split(/[,+]+/)
          .filter((t) => t !== '-')
      : [],
});

const formatTextItem = (checkItem) => ({
  id: checkItem.id,
  type: checkItem?.checkItem?.type || '',
  taskID: checkItem.taskID,
  taskSchedulerID: checkItem.taskSchedulerID,
  detail:
    checkItem?.checkItem?.textData?.message ||
    checkItem?.checkItem?.textData?.text ||
    '',
  channel: checkItem?.checkItem?.textData?.channel || '',
  embeddedUrl: formatEmbeddedUrl(
    checkItem?.checkItem?.textData?.directUrl ||
      checkItem?.checkItem?.metaData?.link ||
      checkItem?.checkItem?.spiderData?.directUrl
  ),
  username: checkItem?.checkItem?.spiderData?.username || null,
  postTime: checkItem?.checkItem?.spiderData?.postTime
    ? Datetime.toFormat(
        checkItem.checkItem.spiderData.postTime,
        'YYYY-MM-DD[T]HH:mm:sszZ',
        'MMMM DD, YYYY HH:mm',
        'UTC',
        'Asia/Bangkok'
      )
    : null,
  displayName: checkItem?.checkItem?.spiderData?.displayName || null,
  imageUrl: checkItem?.checkItem?.spiderData?.imageUrl || null,
  place: checkItem?.checkItem?.spiderData?.place || null,
  post: checkItem?.checkItem?.spiderData?.post
    ? {
        id: checkItem.checkItem.spiderData.post.id,
        message: checkItem.checkItem.spiderData.post.message,
        username: checkItem.checkItem.spiderData.post.username,
        displayName: checkItem.checkItem.spiderData.post.displayName,
        imageUrl: checkItem.checkItem.spiderData.post.imageUrl,
        postTime: Datetime.toFormat(
          checkItem.checkItem.spiderData.post.postTime,
          'YYYY-MM-DD[T]HH:mm:ssz[Z]',
          'MMMM DD, YYYY HH:mm',
          'UTC',
          'Asia/Bangkok'
        ),
      }
    : null,
  comment: checkItem?.checkItem?.spiderData?.comment
    ? {
        id: checkItem.checkItem.spiderData.comment.id,
        message: checkItem.checkItem.spiderData.comment.message,
        username: checkItem.checkItem.spiderData.comment.username,
        displayName: checkItem.checkItem.spiderData.comment.displayName,
        imageUrl: checkItem.checkItem.spiderData.comment.imageUrl,
        postTime: Datetime.toFormat(
          checkItem.checkItem.spiderData.comment.postTime,
          'YYYY-MM-DD[T]HH:mm:ssz[Z]',
          'MMMM DD, YYYY HH:mm',
          'UTC',
          'Asia/Bangkok'
        ),
      }
    : null,
  title:
    checkItem?.checkItem?.textData?.title ||
    checkItem?.checkItem?.metaData?.title ||
    '',
  mainKeyword: checkItem?.checkItem?.textData?.mainKeyword // ['ไม่รับผิดชอบ', 'ขนมปัง']
    ? `${checkItem.checkItem.textData.mainKeyword}` // join by comma to be ไม่รับผิดชอบ,ขนมปัง (it's call toString() method)
        .split(/[,+]+/)
        .filter((t) => t !== '-')
    : [],
  subKeyword: checkItem?.checkItem?.textData?.subKeyword
    ? `${checkItem.checkItem.textData.subKeyword}`
        .split(/[,+]+/)
        .filter((t) => t !== '-')
    : [],
});

const formatRedsmithItem = (checkItem) => ({
  id: checkItem.id,
  type: checkItem.checkItem ? checkItem.checkItem.type : '',
  taskID: checkItem.taskID,
  taskSchedulerID: checkItem.taskSchedulerID,
  detail:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? checkItem.checkItem.redsmithData.message
      : '',
  channel:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? checkItem.checkItem.redsmithData.channel
      : '',
  embeddedUrl:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? checkItem.checkItem.redsmithData.directURL
      : null,
  username:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? checkItem.checkItem.redsmithData.account
      : null,
  postTime:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? Datetime.toFormat(
          checkItem.checkItem.redsmithData.postTime,
          'YYYY-MM-DD[T]HH:mm:ssz[Z]',
          'MMMM DD, YYYY HH:mm',
          'UTC',
          'Asia/Bangkok'
        )
      : null,
  displayName: checkItem.checkItem?.redsmithData?.account || null,
  imageUrl:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? checkItem.checkItem.redsmithData.imageURL
      : null,
  place:
    checkItem.checkItem && checkItem.checkItem.spiderData
      ? checkItem.checkItem.spiderData.place
      : null,
  post:
    checkItem.checkItem &&
    checkItem.checkItem.spiderData &&
    checkItem.checkItem.spiderData.post
      ? {
          id: checkItem.checkItem.spiderData.post.id,
          message: checkItem.checkItem.spiderData.post.message,
          username: checkItem.checkItem.spiderData.post.username,
          displayName: checkItem.checkItem.spiderData.post.displayName,
          imageUrl: checkItem.checkItem.spiderData.post.imageUrl,
          postTime: Datetime.toFormat(
            checkItem.checkItem.spiderData.post.postTime,
            'YYYY-MM-DD[T]HH:mm:ssz[Z]',
            'MMMM DD, YYYY HH:mm',
            'UTC',
            'Asia/Bangkok'
          ),
        }
      : null,
  comment:
    checkItem.checkItem &&
    checkItem.checkItem.spiderData &&
    checkItem.checkItem.spiderData.comment
      ? {
          id: checkItem.checkItem.spiderData.comment.id,
          message: checkItem.checkItem.spiderData.comment.message,
          username: checkItem.checkItem.spiderData.comment.username,
          displayName: checkItem.checkItem.spiderData.comment.displayName,
          imageUrl: checkItem.checkItem.spiderData.comment.imageUrl,
          postTime: Datetime.toFormat(
            checkItem.checkItem.spiderData.comment.postTime,
            'YYYY-MM-DD[T]HH:mm:ssz[Z]',
            'MMMM DD, YYYY HH:mm',
            'UTC',
            'Asia/Bangkok'
          ),
        }
      : null,
  mainKeyword:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? `${checkItem.checkItem.redsmithData.mainKeyword}`
          .split(/[,+]+/)
          .filter((t) => t !== '-')
      : [],
  subKeyword:
    checkItem.checkItem && checkItem.checkItem.redsmithData
      ? `${checkItem.checkItem.redsmithData.subKeyword}`
          .split(/[,+]+/)
          .filter((t) => t !== '-')
      : [],
});

const formatPostTimeCheckItOff = (checkItem) => {
  if (checkItem.checkItem?.data?.postTime) {
    return Datetime.toFormat(
      checkItem.checkItem.data.postTime,
      'YYYY-MM-DD[T]HH:mm:ssz[Z]',
      'MMMM DD, YYYY HH:mm',
      'UTC',
      'Asia/Bangkok'
    );
  }
  if (
    checkItem.checkItem?.metaData['Post date'] &&
    checkItem.checkItem?.metaData['Post time']
  ) {
    return Datetime.toFormat(
      `${checkItem.checkItem.metaData['Post date']} ${checkItem.checkItem.metaData['Post time']}`,
      'YYYY-MM-DD[T]HH:mm:ssz[Z]',
      'MMMM DD, YYYY HH:mm',
      'Asia/Bangkok',
      'Asia/Bangkok'
    );
  }
  return null;
};

const formatCheckItOffItem = (checkItem) => ({
  id: checkItem.id,
  type: checkItem.checkItem?.type || '',
  taskID: checkItem.taskID,
  taskSchedulerID: checkItem.taskSchedulerID,
  title: checkItem.checkItem?.data?.title || '',
  detail: checkItem.checkItem?.data?.message || '',
  channel: checkItem.checkItem?.data?.channel || '',
  embeddedUrl: checkItem.checkItem?.data?.directUrl || null,
  username:
    checkItem.checkItem?.data?.account ||
    checkItem.checkItem?.metaData?.Account ||
    null,
  postTime: formatPostTimeCheckItOff(checkItem),
  displayName:
    checkItem.checkItem?.data?.account ||
    checkItem.checkItem?.metaData?.Account ||
    null,
  imageUrl: checkItem.checkItem?.data?.imageUrl || null,
  place:
    checkItem.checkItem?.data?.source ||
    checkItem.checkItem?.metaData?.Source ||
    null,
  post: checkItem.checkItem?.parentData?.post
    ? {
        id: checkItem.checkItem.parentData.post._id,
        message: checkItem.checkItem.parentData.post.message,
        username: checkItem.checkItem.parentData.post.account,
        displayName: checkItem.checkItem.parentData.post.account,
        imageUrl: checkItem.checkItem.parentData.post.imageUrl,
        postTime: Datetime.toFormat(
          checkItem.checkItem.parentData.post.postTime,
          'YYYY-MM-DD[T]HH:mm:ssz[Z]',
          'MMMM DD, YYYY HH:mm',
          'UTC',
          'Asia/Bangkok'
        ),
      }
    : null,
  comment: checkItem.checkItem?.parentData?.comment
    ? {
        id: checkItem.checkItem.parentData.comment._id,
        message: checkItem.checkItem.parentData.comment.message,
        username: checkItem.checkItem.parentData.comment.account,
        displayName: checkItem.checkItem.parentData.comment.account,
        imageUrl: checkItem.checkItem.parentData.comment.imageUrl,
        postTime: Datetime.toFormat(
          checkItem.checkItem.parentData.comment.postTime,
          'YYYY-MM-DD[T]HH:mm:ssz[Z]',
          'MMMM DD, YYYY HH:mm',
          'UTC',
          'Asia/Bangkok'
        ),
      }
    : null,
  mainKeyword: checkItem.checkItem?.data?.mainKeyword || [],
  subKeyword: checkItem.checkItem?.data?.subKeyword || [],
});

const formatCheckItem = (checkItem) => {
  const type = checkItem.checkItem ? checkItem.checkItem.type : 'none';
  switch (type) {
    case 'zocial-eye-v1.0':
      return formatZocialEyeItemV1(checkItem);
    case 'zocial-eye-v2':
      return formatZocialEyeItemV2(checkItem);
    case 'text':
      return formatTextItem(checkItem);
    case 'redsmith-v1':
      return formatRedsmithItem(checkItem);
    case 'check-it-off':
      return formatCheckItOffItem(checkItem);
    default:
      return { id: 'not-found' };
  }
};

const formatCheckResult = (
  type,
  checkResultList,
  note,
  paymentRate,
  actionType,
  actionTime
) => {
  const typeMapping = {
    HIERARCHY_LABEL: 'HIERARCHY_LABEL',
    MULTIPLE_LABEL: 'MULTIPLE_LABEL',
  };

  return {
    type: typeMapping[type],
    checkResultList: checkResultList.map((checkResult) => ({
      resultList: checkResult.group.map((group, idx) => ({
        group,
        label: checkResult.label[idx],
        value: checkResult.value[idx],
      })),
    })),
    note,
    paymentRate,
  };
};

const formatCheckItemFromExcel = (columns) => ({
  text: columns[0],
  type: columns[1],
});

const formatConfigFromExcel = (columns) => ({
  text: columns[0],
  type: columns[1],
});

const formatTaskPeriod = (data) => ({
  start: data.start,
  end: data.end,
});

const formatTaskData = (data) => ({
  after: data.start,
  before: data.end,
});

const formatUser = (data) => ({
  id: data.id,
  username: data.username,
  password: null,
  email: data.email,
  name: data.name,
  checkableItemLimit: data.checkableItemLimit,
  role: canManageTask({
    user: {
      ...data,
    },
  })
    ? 'manager'
    : 'checker',
  isChangePassword: false,
});

export {
  determineCheckItemImportType,
  formatTask,
  formatTaskList,
  formatSelectedTask,
  formatSelectedConfig,
  formatCheckItem,
  formatCheckItemFromExcel,
  formatCheckItOffItem,
  formatConfigFromExcel,
  formatCheckResult,
  formatHierarchyLabelResultType,
  formatTaskPeriod,
  formatTaskData,
  formatUser,
  formatRedsmithItem,
};
